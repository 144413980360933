/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (app, gsap, SplitText, ScrollTrigger, LazyLoad, Lenis) => {
    /*Constants*/
    const $window = $(window),
      $body = $("body"),
      $header = $("header#site-header"),
      $footer = document.querySelector("footer#site-footer"),
      $burger = $(".burger");

    $window.on("load", function () {
      app.dispachEvent($body, "loaderEnd");
    });

    /*On Window Loaded*/
    $body.on("loaderEnd", () => {
      /*LazyLoad*/
      function lazyParent(el) {
        const lazyContainer = el.closest(".lazy-container");
        lazyContainer.classList.add("is-load");
      }
      window.lazy = new LazyLoad({
        unobserve_entered: true,
        callback_loaded: lazyParent,
      });

      /*Burger Menu*/
      $burger.on("click", function () {
        $body.toggleClass("is-menu");
      });

      /*Close burger menu on resize*/
      function checkDevice() {
        if ("matchMedia" in window) {
          if (window.matchMedia("(min-width:48rem)").matches) {
            $body.removeClass("is-menu");
          }
        }
      }
      window.addEventListener("resize", checkDevice, false);

      /*Lenis update*/
      document.addEventListener("lenis:resize", function (e) {
        lenis.resize();
      });

      /*Header on Scroll*/
      const showAnim = gsap.to($header, {
        y: 0,
        paused: true,
        duration: 0.2,
      });
      ScrollTrigger.create({
        start: "top -100",
        onUpdate: (self) => {
          self.progress * self.end > $header[0].offsetHeight ? showAnim.play() : showAnim.reverse();
        },
      });

      /* SplitText line reveal */
      gsap.utils.toArray(".text-reveal-chars").forEach(function (elem) {
        const splitCharsTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: elem,
            start: "top 70%",
            end: "bottom 50%",
            // scrub:true,
            onComplete: () => {
              innerSplitChars.revert();
              outerSplitChars.revert();
            },
          },
        });
        const outerSplitChars = new SplitText(elem, {
          type: "lines",
          linesClass: "split-outer",
          reduceWhiteSpace: false,
        });
        const innerSplitChars = new SplitText(elem, {
          type: "lines",
          linesClass: "chars-up",
          reduceWhiteSpace: false,
        });
        splitCharsTimeline.from(outerSplitChars.lines, {
          duration: 1,
          yPercent: 100,
          ease: "Circ.easeOut",
          stagger: 0.2,
        });
      });

      /* Footer from behind */
      const main = document.querySelector("main");
      gsap.set($footer, { yPercent: -50 });
      gsap.to($footer, {
        scrollTrigger: {
          trigger: main,
          start: "bottom bottom",
          end: () => `+=${Math.ceil($footer.offsetHeight)}`,
          scrub: true,
        },
        yPercent: 0,
        ease: "none",
      });

      /* Smooth scroll Lenis + GSAP ScrollTrigger */
      const lenis = new Lenis({});
      lenis.on("scroll", ScrollTrigger.update);

      gsap.ticker.add((time) => {
        lenis.raf(time * 1000);
      });
      gsap.ticker.lagSmoothing(0);
    });

    /*Cookie Manager*/
    // new CookieManager($cookieBanner, {
    //   name: "local_cookie_use",
    //   duration: 30,
    //   buttons: {
    //     accept: $cookieAccept,
    //     refuse: $cookieRefuse,
    //     // close: $cookieClose
    //   },
    //   onAccept: () => {},
    //   onRefuse: () => {},
    // });
  },
};

