/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        if ( document.body.classList.contains('post-type-archive-projects') ) {

            /*
            |
            | Constants
            |------------
            */
            const
                $loadMoreBtn = $('.load-more-btn'),
                $postsContainer = $('.projets'),
                $loadMoreContainer = $('.load-more-container'),
                $loadMore = $('.load-more'),
                $loadingContainer = $('.loading-container'),
                $total = $('.projet').length,
                $limit = 5,
                baseUrl = $('.page-wrapper').data('base'),
                lang = $('body').data('lang')
                ;

            let xhr = null;

            if ($total < $limit) {
                $loadMoreContainer.hide()
            }
            /*
            |
            | Load more ajax
            |--------------
            */
            $loadMoreBtn.on('click', function (e) {

                abort(xhr)

                let offset = $postsContainer.find('.projet').length
                let url = `${baseUrl}/ajax/projects/${offset}/?lang=${lang}`

                xhr = $.ajax({
                    url: url,
                    type: 'GET',
                    method: 'GET',
                    dataType: 'json',
                    beforeSend: () => {
                        $loadMore.hide()
                        $loadingContainer.show()
                    },
                    success: (response, status) => {

                        $postsContainer.append(response);
                        $loadingContainer.hide()

                        if (!$postsContainer.find('.no-more-post').length) {
                            $loadMoreContainer.show()
                            $loadMore.show()
                        } else {
                            $loadMoreContainer.hide()
                        }

                        xhr = null
                    },
                    complete: function (response) {

                        setTimeout(() => {
                            window.lazy.update();
                            document.dispatchEvent(new Event("lenis:resize"));
                        }, 400)

                    },
                    error: (response, status, error) => {
                        console.log(error)
                    }
                });

            });

            /*
            | HELPER
            |
            | abort ajax request
            |------------
            */
            function abort(xhrVar, $list = null) {
                if (xhrVar !== null) {
                    xhrVar.abort()
                }

                if ($list !== null) {
                    $list.find('.loading--ajax').hide()
                }
            }
        }

    }
}
