/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (gsap, ScrollTrigger, Swiper) => {
    const marqueeSwiper1 = new Swiper("#marqueeSwiper1", {
      loop: true,
      freeMode: true,
      grabCursor: true,
      slidesPerView: 5,
      spaceBetween: 24,
      speed: 2500,
      autoplay: {
        delay: 1,
        disableOnInteraction: false,
      },
      breakpoints: {
        1200: {
          slidesPerView: 9,
          spaceBetween: 40,
        },
      },
    });
    
    const marqueeSwiper2 = new Swiper("#marqueeSwiper2", {
      loop: true,
      freeMode: true,
      grabCursor: true,
      spaceBetween: 24,
      slidesPerView: 5,
      speed: 2500,
      autoplay: {
        delay: 1,
        reverseDirection: true,
        disableOnInteraction: false,
      },
      breakpoints: {
        1200: {
          slidesPerView: 9,
          spaceBetween: 40,
        },
      },
    });
    
    const marquee = document.querySelectorAll("#marquee");
    let homeSwipers = document.querySelectorAll(".home-swiper");
    
    const swiperTimeline = new gsap.timeline();
    
    swiperTimeline.set(homeSwipers, { willChange: "transform" });
    
    swiperTimeline.fromTo(
      homeSwipers[0],
      { x: 250 },
      {
        x: -250,
        ease: "none",
      },
      0
    );
    
    swiperTimeline.fromTo(
      homeSwipers[1],
      { x: -250 },
      {
        x: 250,
        ease: "none",
      },
      0
    );
    
    swiperTimeline.set(homeSwipers, { willChange: "auto" });
    
    ScrollTrigger.create({
      trigger: marquee,
      animation: swiperTimeline,
      start: "top bottom",
      end: "bottom top",
      scrub: 0.3,
      refreshPriority: -14,
    });
    

    


    /*const panels = gsap.utils.toArray("#services-scroll-x .service");
    const wrapper = document.getElementById('services-scroll-x_inner');

    function initServicesScroll() {
      if ( document.documentElement.clientWidth >= 1280 ) {
        let toScroll = document.documentElement.clientWidth - (panels.length * panels[0].offsetWidth + (panels.length - 1) * 80 + document.documentElement.clientWidth * .082 * 2);
        if ( toScroll < 0 ) {
          gsap.to(wrapper, {
            x: toScroll,
            ease: "none",
            scrollTrigger: {
              trigger: '#services-scroll-x',
              pin: true,
              scrub: 1,
              start: '-=' + document.getElementById('site-header').offsetHeight,
              end: "+=" + (-(toScroll)),
            }
          });
        }
      } else {
        const servicesSwiper = new Swiper("#services-scroll-x", {
          loop: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      }
    }
    initServicesScroll();
    window.addEventListener("resize", function() {
      gsap.killTweensOf(wrapper);
      initServicesScroll();
    }, false);*/

    const servicesSwiper = new Swiper("#services-scroll-x", {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1280: {
          slidesPerView: 3,
        },
      },
    });


    const temoignagesSignaturesSwiper = new Swiper("#temoignagesSignaturesSwiper", {
      loop: true,
      allowTouchMove: false,
      spaceBetween: 24,
    });

    const temoignagesSwiper = new Swiper("#temoignagesSwiper", {
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      controller: {
        control: temoignagesSignaturesSwiper,
        by: 'container',
      },
    });
    
  },
};

