/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";
import CustomEase from "@lib/gsap-pro/CustomEase";
import LazyLoad from "vanilla-lazyload";
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Controller } from "swiper";
import "swiper/swiper-bundle.css";
import Accordion from "accordion-js";
import Lenis from "@studio-freight/lenis";

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, Controller]);

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);

/*Importing Components*/
// import CookieManager from "@components/cookies";

/*Importing Utils*/
import Router from "@utils/router.js";

/*
|
| Importing App files
|----------------------
*/
import * as app from "@components/global.js";
import news from "@pages/archive-news.js";
import projects from "@pages/archive-projects.js";
import article from "@pages/single-news.js";
import main from "@pages/main.js";
import home from "@pages/home.js";
import contact from "@pages/contact.js";
import services from "@pages/services.js";
import pages from "@pages/default.js";
import device from "@utils/device.js";
import desktop from "@utils/desktop.js";

/*
|
| Routing
|----------
*/

const routes = new Router([
  {
    file: main,
    dependencies: [app, gsap, SplitText, ScrollTrigger, LazyLoad, Lenis],
  },
  {
    file: news,
  },
  {
    file: projects,
  },
  {
    file: article,
    dependencies: [app, gsap, ScrollTrigger],
  },
  {
    file: device,
    dependencies: [Swiper, Navigation, Pagination, Scrollbar],
    resolve: ".is-device",
  },
  {
    file: desktop,
    dependencies: [gsap],
    resolve: ".is-desktop",
  },
  {
    file: home,
    dependencies: [gsap, ScrollTrigger, Swiper, Accordion],
    resolve: "#page-home",
  },
  {
    file: contact,
    dependencies: [],
    resolve: "#template-contact",
  },
  {
    file: services,
    dependencies: [gsap, ScrollTrigger, Swiper],
    resolve: "#template-services",
  },
  {
    file: pages,
    dependencies: [gsap, ScrollTrigger, Swiper, Accordion],
    resolve: "#template-default, #template-seo, #template-casPratiques",
  },
]);

/*Run*/
(($) => {
  routes.load();
})(jQuery);

