/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (Swiper, Navigation, Pagination, Scrollbar) => {
    let popinSwiper = document.querySelector("#popinSwiper");
    if (!!popinSwiper) {
      popinSwiper = new Swiper("#popinSwiper", {
        // loop: false,
        modules: [Pagination, Navigation, Scrollbar],
        scrollbar: {
          el: ".popin-scrollbar",
          draggable: true,
          enabled: true,
        },
        on: {
          slideChange: function (swiper) {
            let slide = swiper.slides[swiper.activeIndex];
            let popin = slide.querySelector(".popin");

            let oldCountry = document.querySelector(`._active-country`);
            let newCountry = document.querySelector(`#worldmap[data-countries] svg #${popin.dataset.country}`);
            if (oldCountry != newCountry) {
              if (!!oldCountry) oldCountry.classList.remove("_active-country");
              if (!!newCountry) newCountry.classList.add("_active-country");
            }
          },
          init: function (swiper) {
            let slide = swiper.slides[swiper.activeIndex];
            let popin = slide.querySelector(".popin");
            let country = document.querySelector(`#worldmap[data-countries] svg #${popin.dataset.country}`);
            if (!!country) country.classList.add("_active-country");
          },
        },
      });
    }
  },
};

