/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: () => {
    let btnSwitchers = document.querySelectorAll(".btn-switch");
    if (btnSwitchers.length) {
      btnSwitchers.forEach((btnSwitcher) => {
        btnSwitcher.addEventListener("click", (e) => {
          let oldSwitch = document.querySelector(`[data-target]._selected`);
          let newSwitch = e.target;
          let pane = newSwitch.dataset.target;
          let oldPane = document.querySelector("[data-pane]._show");
          let newPane = document.querySelector(`[data-pane="${pane}"]`);
          if (oldPane != newPane) {
            if (!!oldSwitch) oldSwitch.classList.remove("_selected");
            if (!!newSwitch) newSwitch.classList.add("_selected");
            if (!!oldPane) oldPane.classList.remove("_show");
            if (!!newPane) newPane.classList.add("_show");
          }
          // lenis.resize();
          // ScrollTrigger.refresh({ safe: true });
          document.dispatchEvent(new Event("lenis:resize"));
        });
      });
      btnSwitchers[0].querySelector("[data-target]").click();
    }
  },
};

