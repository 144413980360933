/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (gsap, ScrollTrigger, Swiper, Accordion) => {
    
    
    document.querySelectorAll('.tk_offre_tabs_marches').forEach(function(container) {
      container.querySelectorAll('.tabs a').forEach(function(a) {
        a.addEventListener('click', function(e) {
          e.preventDefault();
          if ( !a.classList.contains('active') ) {
            container.querySelectorAll('.tabs a.active').forEach(function(btn) {
              btn.classList.remove('active');
            });
            container.querySelectorAll('.tab-panes .pane.active').forEach(function(pane) {
              pane.classList.remove('active');
            });
            container.querySelector('[data-pane="' + a.dataset.tab + '"]').classList.add('active');
            a.classList.add('active');
          }
        });
      });
    });
    

    document.querySelectorAll('.tk_offre_blocs_offres').forEach(function(container) {
      const servicesSwiper = new Swiper(container.querySelector('.offres-scroll-x'), {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1280: {
            slidesPerView: 3,
          },
        },
      });
    });

    document.querySelectorAll('.tk_offre_faq .accordion').forEach(function(container) {
      new Accordion(container);
    });
    
    
  },
};

