/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (gsap) => {
    let worldMap = document.querySelector("#worldmap[data-countries]");
    let oldPopin = false;

    if (!!worldMap) {
      var countrySelection = worldMap.dataset.countries.split(",");
      var countries = [];
      countrySelection.forEach((country) => {
        if (!!country) countries.push(document.querySelector(`svg #${country}`));
      });

      countries.forEach((country) => {
        country.classList.add("_selected");

        country.addEventListener("click", (e) => {
          let lastCountry = document.querySelector(`._active-country`);
          if (lastCountry == country) return;
          if (!!lastCountry) lastCountry.classList.remove("_active-country");
          country.classList.add("_active-country");

          let currentTimeline = gsap.timeline();
          let currentCountry = e.target;
          let currentPopin = document.querySelector(`[data-country="${country.id}"]`);
          let currentStroke = currentPopin.querySelector(".stroke");
          let currentDot = currentPopin.querySelector(".dot");

          let worldRect = worldMap.getBoundingClientRect();
          let countryRect = currentCountry.getBoundingClientRect();
          let popinRect = currentPopin.getBoundingClientRect();

          let countryCenterX = countryRect.left + countryRect.width / 2 - worldRect.left;
          let countryCenterY = countryRect.top + countryRect.height / 2 - worldRect.top;
          let countryHalfSize = Math.max(countryRect.width / 2, countryRect.height / 2);

          let strokeLength = Math.sqrt(2 * Math.pow(countryHalfSize, 2));

          let cadran = 1;
          if (countryCenterX > worldRect.width / 2 && countryCenterY > worldRect.height / 2) {
            cadran = 3;
          } else if (countryCenterX > worldRect.width / 2) {
            cadran = 2;
          } else if (countryCenterY > worldRect.height / 2) {
            cadran = 4;
          }

          let clicX = Math.floor(e.clientX - worldRect.left);
          let clicY = Math.floor(e.clientY - worldRect.top);

          currentTimeline.set(currentPopin, {
            pointerEvents: "auto",
            userSelect: "auto",
            xPercent: 0,
            yPercent: 0,
            x: 0,
            y: 0,
          });
          currentTimeline.to(currentPopin, {
            opacity: 1,
          });

          switch (cadran) {
            case 1:
              currentTimeline.set(
                currentStroke,
                {
                  rotate: "-135deg",
                  x: 2.5,
                  y: 2.5,
                },
                "<"
              );
              currentTimeline.to(
                currentPopin,
                {
                  left: clicX,
                  top: clicY,
                },
                "<"
              );

              currentTimeline.to(currentPopin, {
                left: clicX + countryHalfSize,
                top: clicY + countryHalfSize,
              });
              currentTimeline.to(
                currentDot,
                {
                  x: -countryHalfSize,
                  y: -countryHalfSize,
                },
                "<"
              );
              break;
            case 2:
              currentTimeline.set(
                currentDot,
                {
                  left: "100%",
                },
                "<"
              );
              currentTimeline.set(
                currentStroke,
                {
                  left: "100%",
                  rotate: "-45deg",
                  x: -2.5,
                  y: 2.5,
                },
                "<"
              );
              currentTimeline.to(
                currentPopin,
                {
                  left: clicX - popinRect.width,
                  top: clicY,
                },
                "<"
              );
              currentTimeline.to(currentPopin, {
                left: clicX - popinRect.width - countryHalfSize,
                top: clicY + countryHalfSize,
              });
              currentTimeline.to(
                currentDot,
                {
                  x: countryHalfSize,
                  y: -countryHalfSize,
                },
                "<"
              );
              break;
            case 3:
              currentTimeline.set(
                currentDot,
                {
                  left: "100%",
                  top: "100%",
                },
                "<"
              );
              currentTimeline.set(
                currentStroke,
                {
                  left: "100%",
                  top: "100%",
                  rotate: "45deg",
                  x: -2.5,
                  y: -2.5,
                },
                "<"
              );
              currentTimeline.to(
                currentPopin,
                {
                  left: clicX - popinRect.width,
                  top: clicY - popinRect.height,
                },
                "<"
              );
              currentTimeline.to(currentPopin, {
                left: clicX - popinRect.width - countryHalfSize,
                top: clicY - popinRect.height - countryHalfSize,
              });
              currentTimeline.to(
                currentDot,
                {
                  x: countryHalfSize,
                  y: countryHalfSize,
                },
                "<"
              );
              break;
            case 4:
              currentTimeline.set(
                currentDot,
                {
                  top: "100%",
                },
                "<"
              );
              currentTimeline.set(
                currentStroke,
                {
                  top: "100%",
                  rotate: "135deg",
                  x: 2.5,
                  y: -2.5,
                },
                "<"
              );
              currentTimeline.to(
                currentPopin,
                {
                  left: clicX,
                  top: clicY - popinRect.height,
                },
                "<"
              );
              currentTimeline.to(currentPopin, {
                left: clicX + countryHalfSize,
                top: clicY - popinRect.height - countryHalfSize,
              });
              currentTimeline.to(
                currentDot,
                {
                  x: -countryHalfSize,
                  y: countryHalfSize,
                },
                "<"
              );
              break;
            default:
              break;
          }

          currentTimeline.to(
            currentStroke,
            {
              width: strokeLength,
            },
            "<"
          );

          currentTimeline.play();
          if (oldPopin) fadePopin(oldPopin);
          oldPopin = currentPopin;
        });
      });
    }

    let popins = document.querySelectorAll(".popin");
    popins.forEach((popin) => {
      popin.addEventListener("click", (e) => {
        if (e.target.classList.contains("close")) {
          let lastCountry = document.querySelector(`svg #${popin.dataset.country}`);
          if (!!lastCountry) lastCountry.classList.remove("_active-country");
          if (oldPopin) fadePopin(oldPopin);
        }
      });
    });

    let fadePopin = (popin) => {
      let popinDot = popin.querySelector(".dot");
      let popinStroke = popin.querySelector(".stroke");
      let popinTimeline = gsap.timeline();
      popinTimeline.set(popin, { pointerEvents: "none", userSelect: "none" });
      popinTimeline.to(popinDot, { x: 0, y: 0 });
      popinTimeline.to(popinStroke, { width: 0 }, "<");
      popinTimeline.to(popin, { opacity: 0 }, "<");
      popinTimeline.play();
      oldPopin = false;
    };
  },
};

