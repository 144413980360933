/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (gsap, ScrollTrigger, Swiper, Accordion) => {
    const marqueeSwiper1 = new Swiper("#marqueeSwiper1", {
      loop: true,
      freeMode: true,
      grabCursor: true,
      slidesPerView: 5,
      spaceBetween: 24,
      speed: 2500,
      autoplay: {
        delay: 1,
        disableOnInteraction: false,
      },
      breakpoints: {
        1200: {
          slidesPerView: 9,
          spaceBetween: 40,
        },
      },
    });

    const marqueeSwiper2 = new Swiper("#marqueeSwiper2", {
      loop: true,
      freeMode: true,
      grabCursor: true,
      spaceBetween: 24,
      slidesPerView: 5,
      speed: 2500,
      autoplay: {
        delay: 1,
        reverseDirection: true,
        disableOnInteraction: false,
      },
      breakpoints: {
        1200: {
          slidesPerView: 9,
          spaceBetween: 40,
        },
      },
    });

    const lotties = document.querySelectorAll(".lottie");

    const lottieObserver = new window.IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          let lottie = entry.target;
          let iframe = lottie.querySelector("iframe");
          let player = new Vimeo.Player(iframe);
          if (entry.isIntersecting && lottie.classList.contains("_show")) {
            player.play();
          } else {
            player.pause();
          }
          return;
        });
      },
      {
        root: null,
        threshold: 0.01,
      }
    );

    for (const lottie of lotties) {
      lottieObserver.observe(lottie);
    }

    const textSwiperContainer = document.querySelector("#textSwiper");
    if (!!textSwiperContainer) {
      let slide = textSwiperContainer.querySelector(".swiper-slide");
      if (!!slide) {
        let slideHeight = slide.getBoundingClientRect().height;
        textSwiperContainer.style.height = `${Math.ceil(slideHeight)}px`;
        const textSwiper = new Swiper("#textSwiper", {
          autoplay: true,
          loop: true,
          direction: "vertical",
          slidesPerView: 1,
          spaceBetween: 10,
          allowTouchMove: false,
        });
        setInterval(() => {
          textSwiper.slideNext();
        }, 2500);
      }
    }

    const marquee = document.querySelectorAll("#marquee");
    let homeSwipers = document.querySelectorAll(".home-swiper");

    const swiperTimeline = new gsap.timeline();

    swiperTimeline.set(homeSwipers, { willChange: "transform" });

    swiperTimeline.fromTo(
      homeSwipers[0],
      { x: 250 },
      {
        x: -250,
        ease: "none",
      },
      0
    );

    swiperTimeline.fromTo(
      homeSwipers[1],
      { x: -250 },
      {
        x: 250,
        ease: "none",
      },
      0
    );

    swiperTimeline.set(homeSwipers, { willChange: "auto" });

    ScrollTrigger.create({
      trigger: marquee,
      animation: swiperTimeline,
      start: "top bottom",
      end: "bottom top",
      scrub: 0.3,
      refreshPriority: -14,
    });

    // const motionAccordion = new Accordion("#motionAccordion", {});

    const tileAccordion = new Accordion("#tileAccordion", {
      beforeOpen: (accordion) => {
        const odlMotion = document.querySelector(`#motionsFrame [data-accordion]._show`);
        const newMotion = document.querySelector(`#motionsFrame [data-accordion="${accordion.dataset.motion}"]`);
        if (!!odlMotion) {
          odlMotion.classList.remove("_show");
          let iframe = odlMotion.querySelector('iframe');
          let player = new Vimeo.Player(iframe);
          player.pause();
        }
        if (!!newMotion) {
          newMotion.classList.add("_show");
          let iframe = newMotion.querySelector('iframe');
          let player = new Vimeo.Player(iframe);
          player.play();
        }
      },
      onOpen: () => {
        ScrollTrigger.refresh({ safe: true });
      },
      onClose: () => {
        ScrollTrigger.refresh({ safe: true });
      },
    });
    tileAccordion.open(0);
  },
};

