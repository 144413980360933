/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap, ScrollTrigger) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $window = $(window),
            $progress = $('#temps_lecture')
            ;


        /*
        |
        | Progress bar
        |------------
        */
        if ( $progress.get().length ) {
            const progressbar = $('#temps_lecture .progress');
            const progress = gsap.to($progress, {
                paused: true,
                scrollTrigger: { scrub: 0.1 },
                onUpdate: () => {
                    if ( progress ) {
                        progressbar.css('background', 'conic-gradient(var(--primary) ' + (360 * progress.progress()) + 'deg, var(--primary-opac20) ' + (360 * progress.progress()) + 'deg) border-box');
                    }
                }
            });
        }

    }
}
